/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import teacher from "../src/assets/images/teacher.png"
import ecommerce from "../src/assets/images/ecommerce.png"
import pizza from "../src/assets/images/pizza.svg"
import companyIcon from "../src/assets/images/skyline.png"

export const agentLogo = {
    "Aria-W3villa-AI-Agent": companyIcon,
    "school-teacher": teacher,
    "e-commerce": ecommerce,
    "pizzaHome": pizza,
}

export const agentName = {
    "Aria-W3villa-AI-AgentWeb": "Aria-W3villa-AI-Agent",
    "Aria-W3villa-AI-AgentCall": "Aria-W3villa-AI-Agent-call",
    "school-teacherWeb": "school-teacher",
    "school-teacherCall": "school-teacher",
    "e-commerceWeb": "e-commerce",
    "e-commerceCall": "e-commerce",
    "pizzaHomeWeb": "pizza-home-web",
    "pizzaHomeCall": "pizza-home-call",
}