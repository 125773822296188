/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import tick from "../../assets/images/check_mark.png";

function ThankYou({ content }) {
    const navigate = useNavigate();

    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            navigate('/');
        }, 5000);

        return () => clearTimeout(redirectTimer);
    }, [navigate]);

    return (
        <>
            <section className="contact-wrap">
                <div className="thank-wrap">
                    <div className='thank-content'>Thank You !</div>
                    <div className='tick-wrap'>
                        <img src={tick} alt='image'/>
                    </div>
                    <div className='fs-4 pb-4'>
                        {content}
                    </div>
                    <hr />
                    <div>Please wait! you are being redirected to home page.</div>
                </div>
            </section>
        </>
    );
}

ThankYou.propTypes = {
    content: PropTypes.node.isRequired,
};

export default ThankYou;
