/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useState } from 'react';
import { Form, Button, Container } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createContact, _verifyEmailOtp } from '../../api/Api';

function ConfirmOtp({ setContactSubmitted }) {
    const navigate = useNavigate();
    const [otpFormData, setOtpFormData] = useState({
        email_otp: ""
    });

    const location = useLocation();
    const { formData } = location.state || {};

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await _verifyEmailOtp({ "body": { email: formData.email, otp: otpFormData.email_otp } })

            if (response.status === 200) {
                const contactResponse = await createContact({ "body": formData })

                if (contactResponse.data.status_code === 500) {
                    showToastMessage(false, "an error occured")
                    console.log("error in saving record", contactResponse.data)
                } else {
                    setContactSubmitted(true)
                    navigate('/thank-you');
                }
            }

        } catch (error) {
            showToastMessage(false, error.response.data.detail)
            console.log(error)
        }

    };

    const showToastMessage = (success, message) => {
        if (success) {
            toast.success(message, {
                position: "top-center",
                theme: "colored",
            });
        } else {
            toast.error(message, {
                position: "top-center",
                theme: "colored",
            });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setOtpFormData({
            ...otpFormData,
            [name]: value
        });
    };

    return (
        <>
            <section className="contact-wrap">
                <div className="contact">
                    <Container>
                        <div className="contactInner">
                            <div className='otp-wrap'>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="form-wrap" controlId="formEmailOtp">
                                        <Form.Label>Email Otp</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email_otp"
                                            placeholder="Enter email otp"
                                            className="p-3"
                                            value={otpFormData.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <div className='pt-2'>An otp has been sent to your email.</div>
                                    <Button variant="primary" type="submit" className="btn-theme mt-3 w-100" block>
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

ConfirmOtp.propTypes = {
  setContactSubmitted: PropTypes.func.isRequired,
};

export default ConfirmOtp;
