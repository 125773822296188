/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useRef, useState, useEffect } from 'react'
import { MessageList } from "react-chat-elements";
import { useLocation } from 'react-router-dom';
import tick from "../../assets/images/check_mark.png"
import { wsBaseUrl } from '../../api/Api';
import convertMarkdownToHtml from '../../utils/utils';

function CallConversation() {
    const messageListRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const caller_num = query.get('caller_num');


    useEffect(() => {
        const callerNum = "+" + caller_num;
        let ws;

        try {
            ws = new WebSocket(`${wsBaseUrl}/${callerNum}`);

            ws.onopen = () => {
                console.log('WebSocket connection opened');
            };

            ws.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    let newMessage;

                    if (message?.user) {
                        newMessage = messageFormat("right", "text", "you", message.user);
                    } else {
                        const convertedHtml = convertMarkdownToHtml(message.ai);
                        newMessage = messageFormat("left", "text", "assistant", <div dangerouslySetInnerHTML={{ __html: convertedHtml }} />);
                    }

                    setMessages(prevMessages => [...prevMessages, newMessage]);

                    if (messageListRef.current) {
                        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
                    }
                } catch (error) {
                    console.error('Error processing WebSocket message:', error);
                }
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed');
            };
        } catch (error) {
            console.error('WebSocket connection failed:', error);
        }

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [caller_num]);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages]);

    const messageFormat = (position, type, title, data) => ({
        position,
        type,
        title,
        [type === "text" ? "text" : "data"]: data
    });

    return (
        <>
            <section>
                <div className='chatWrap'>
                    <div className="home-wrap">
                        <div className='audio-wrap'>
                            <div className='audio-visulaize'>
                                <div className="call-thank-wrap">
                                    <div className='thank-content'>Thank You !</div>
                                    <div className='tick-wrap'>
                                        <img src={tick} alt='image' />
                                    </div>
                                    <div className='fs-5 pb-4'>
                                        We have received your request for a call from AudioBot. You will soon receive a call from AudioBot.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='home' ref={messageListRef} >
                            {messages && <MessageList
                                className='message-list'
                                lockable={true}
                                toBottomHeight={'100%'}
                                dataSource={messages}
                            />}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CallConversation
