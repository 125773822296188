/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React from "react";
import { ToastContainer } from 'react-toastify';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import './Contact.scss';
import logo from '../../assets/images/logo.png'

function ContactLayout() {
    return (
        <>
            <div className='home-title'>
                <div className='nav-wrap'>
                    <Link to={'/'}>
                        <div className="logo-wrap">
                            <img src={logo} alt="" />
                            <h1>Audio Bot (W3villa)</h1>
                        </div>
                    </Link>
                    <Link to={'/'} className="d-flex" style={{alignItems:"center"}}>
                        <FontAwesomeIcon icon={faHouse} className="me-1"/>
                        <div style={{ fontSize: "20px", fontWeight: "500" }}>Home</div>
                    </Link>
                </div>
            </div>
            <ToastContainer />
            <Outlet />
        </>
    )
}

export default ContactLayout
