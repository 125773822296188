/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { _generateEmailOtp } from "../../api/Api";
import CountrySelect from "./CountrySelect";

const ContactUs = ({ setOtpGenerated }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        country_code: ""
    });
    const [selectedCountry, setSelectedCountry] = useState({});
    const [otpGenerate, setOtpGenerate] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!formData.name || !formData.email || !formData.message) {
            toast.error("Please fill out all fields.", {
                position: "top-center",
                theme: "colored",
            });
            return;
        }

        if (!selectedCountry || Object.keys(selectedCountry).length === 0) {
            toast.error("Please select a country.", {
                position: "top-center",
                theme: "colored",
            });
            return;
        } else {
            formData.country_code = selectedCountry.value
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            toast.error("Please enter a valid email address.", {
                position: "top-center",
                theme: "colored",
            });
            return;
        }


        try {
            setOtpGenerate(true)
            const response = await _generateEmailOtp({ "body": { email: formData.email } })
            // const response = { status: 200 }
            if (response.status === 200) {
                setOtpGenerate(false)
                showToastMessage(true)
                setOtpGenerated(true)

                navigate('/verify-otp', { state: { formData } });
            }
        } catch (error) {
            setOtpGenerate(false)
            showToastMessage(false)
            console.log(error)
        }

    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const showToastMessage = (success) => {
        if (success) {
            toast.success("Otp generated successfully", {
                position: "top-center",
                theme: "colored",
            });
        } else {
            toast.error("An error occurred, generating otp", {
                position: "top-center",
                theme: "colored",
            });
        }
    };


    return (
        <>
            <section className="contact-wrap">
                <div className="contact">
                    <Container>
                        <div className="contactInner">
                            <div>
                                <h2 className="text-center">Contact Us</h2>
                                <br />
                            </div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="contact-desc">
                                        <h4>Welcome to our contact us page!</h4>
                                        <p> We&apos;re delighted to assist you further. If you have a specific inquiry or would like to request a custom persona, please fill out the form with your details. </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="form-wrap" controlId="formName">
                                                {/* <Form.Label>Name</Form.Label> */}
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter your name"
                                                    className="p-3"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            {/* <Form.Group className="mt-3 form-wrap" controlId="formPhone">
                                // <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="phone"
                                    placeholder="Enter your phone number"
                                    className="p-3"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </Form.Group> */}
                                            <Form.Group className="mt-3 form-wrap" controlId="formEmail">
                                                {/* <Form.Label>Email</Form.Label> */}
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    className="p-3"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-3 form-wrap" controlId="formEmail">
                                                {/* <Form.Label>Select Country</Form.Label> */}
                                                <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
                                            </Form.Group>
                                            <Form.Group className="mt-3 form-wrap" controlId="formMessage">
                                                {/* <Form.Label>Message</Form.Label> */}
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    name="message"
                                                    placeholder="Enter your message"
                                                    className="p-3"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Button variant="primary" type="submit" className="btn-theme mt-3 w-100" block>
                                                {otpGenerate ? "generating..." : "Generate OTP"}
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    );
};

ContactUs.propTypes = {
  setOtpGenerated: PropTypes.func.isRequired,
};

export default ContactUs;
