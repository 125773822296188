/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Select from "react-select";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { PiPhoneCallThin } from "react-icons/pi";

import { _createCallDetail, _verifyPhoneOtp } from '../../api/Api';
import { agentName } from '../../constant';

function FormModal({ formModal, handleForm, setCall, defaultAgent }) {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [value, setValue] = useState();
    const [name, setName] = useState(null);
    const [connecting, setConnecting] = useState(false);
    const languages = [
        { value: "en-US", label: "English" },
        { value: "hi-IN", label: "Hindi" },
        { value: "es-ES", label: "Spanish" },
        { value: "fr-FR", label: "French" },
        { value: "it-IT", label: "Italian" },
    ];
    const agents = [
        { value: "Aria-W3villa-AI-Agent", label: "Aria-W3villa-AI-Agent" },
        { value: "school-teacher", label: "school-teacher" },
        { value: "e-commerce", label: "e-commerce" },
        { value: "pizzaHome", label: "pizzaHome" }
    ];
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [otpModal, setOtpModal] = useState(false);
    const [user, setUser] = useState(null);
    const [showResendOtp, setShowResendOtp] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const countdownInterval = useRef(null);

    useEffect(() => {
        setSelectedAgent({ value: defaultAgent, label: defaultAgent });
    }, [defaultAgent]);

    const handleSubmit = async (resend) => {
        try {
            if (!name || !value || !selectedLanguage) {
                toast.error('Please fill all fields', { position: 'top-center', theme: 'colored' });
                return;
            }
            setConnecting(true);
            const body = { name: name, phone: value, language: selectedLanguage.value };
            const response = await _createCallDetail({ body });
            if (response.status === 200) {
                handleForm('callModal', false);
                setUser(response.data.data);
                handleOtpModal(true);
                if (resend) {
                    toast.success('OTP resent successfully', {
                        position: 'top-center',
                        theme: 'colored',
                    });
                }
            }
            setConnecting(false);
        } catch (error) {
            setConnecting(false);
            toast.error('OTP generation failed', {
                position: 'top-center',
                theme: 'colored',
            });
            console.log(error);
        }
    };

    const handleOtp = async () => {
        try {
            setConnecting(true);
            const otpStr = otp.join('');
            const agent = agentName[selectedAgent.value + "Call"];
            const body = { otp: otpStr, user_id: user, agent: agent };
            const response = await _verifyPhoneOtp({ body });
            setConnecting(false);

            if (response.data.status) {
                setOtp(['', '', '', '', '', '']);
                setValue(null);
                setName(null);
                setSelectedLanguage(languages[0]);
                handleOtpModal(false);
                setCall(true);
                navigate(`/conversation?caller_num=${value.substring(1)}`);
            } else if (response.data.status_code === 400) {
                setOtp(['', '', '', '', '', '']);
                toast.error("Please enter correct otp", {
                    position: "top-center",
                    theme: "colored",
                });
            }

        } catch (error) {
            setConnecting(false);
            setOtp(['', '', '', '', '', '']);
            toast.error("Internal server error", {
                position: "top-center",
                theme: "colored",
            });
        }
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1);
        setOtp(newOtp);

        if (value && index < 5) {
            const nextInput = document.querySelectorAll('.otp-input')[index + 1];
            nextInput.focus();
        }
    };

    const handleOtpModal = (boolValue) => {
        setOtpModal(boolValue);
        setShowResendOtp(false);
        if (boolValue) {
            startResendOtpTimer();
        }
    };

    const startResendOtpTimer = () => {
        if (countdownInterval.current) {
            clearInterval(countdownInterval.current);
        }

        setShowResendOtp(false);
        setCountdown(30);

        countdownInterval.current = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownInterval.current);
                    setShowResendOtp(true);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const handleResendOtp = async () => {
        handleSubmit(true);
        startResendOtpTimer();
    };

    useEffect(() => {
        if (otpModal) {
            startResendOtpTimer();
        }

        return () => {
            if (countdownInterval.current) {
                clearInterval(countdownInterval.current);
            }
        };
    }, [otpModal]);

    return (
        <>
            <Modal show={formModal} onHide={() => handleForm("callModal", false)}>
                <Modal.Header closeButton className='border-0 pb-0'>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center headWicon'>
                        <PiPhoneCallThin />

                        <h4><b>Recieve a phone call from AudioBot</b></h4>
                    </div>
                    <div className="">
                        <Form className='formStyle'>
                            <Form.Group className="form-wrap" controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    className="p-3"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3 form-wrap" controlId="formPhone">
                                <Form.Label>Phone Number</Form.Label>
                                <PhoneInput
                                    international
                                    defaultCountry="IN"
                                    value={value}
                                    onChange={setValue}
                                    className='phone-input' />
                            </Form.Group>
                            <Form.Group className="mt-3 form-wrap" controlId="formLanguage">
                                <Form.Label>Language</Form.Label>
                                <Select
                                    className="country-drop"
                                    options={languages}
                                    value={selectedLanguage}
                                    onChange={(selectedOption) => setSelectedLanguage(selectedOption)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3 form-wrap" controlId="formAgent">
                                <Form.Label>Agent</Form.Label>
                                <Select
                                    className="country-drop"
                                    options={agents}
                                    value={selectedAgent}
                                    onChange={(selectedOption) => setSelectedAgent(selectedOption)}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className='mt-4 mb-3 d-flex justify-content-center'>
                        <Button variant="primary" className='w-100' onClick={() => handleSubmit(false)} disabled={connecting} >
                            <FontAwesomeIcon icon={faPhone} className='me-4' /> {connecting ? "Connecting..." : "Get a call from AudioBot"}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={otpModal} onHide={() => handleOtpModal(false)}>
                <Modal.Header closeButton className='border-0 pb-0'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center mb-4'>
                        <h4>Verify OTP</h4>
                        <p>Please enter the OTP</p>
                    </div>
                    <div className="">
                        <Form>
                            <Form.Group className="form-wrap d-flex justify-content-between" controlId="formOtp">
                                <div className="d-flex otpInput">
                                    {[...Array(6)].map((_, index) => (
                                        <Form.Control
                                            key={index}
                                            type="text"
                                            placeholder='X'
                                            maxLength={1}
                                            value={otp[index] || ''}
                                            onChange={(event) => handleOtpChange(index, event.target.value)}
                                            className="otp-input mx-1"
                                        />
                                    ))}
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className='text-center mt-4 font-14'>
                        Resend OTP in <b>{`00:${countdown.toString().padStart(2, '0')}`}</b>
                    </div>
                    {showResendOtp && (
                        <div className='mt-4 text-center mb-4'>
                            <Button variant="secondary" className="px-4" onClick={handleResendOtp}>
                                Resend OTP
                            </Button>
                        </div>
                    )}
                    <div className='mt-4 text-center mb-4'>
                        <Button variant="success" className='px-4' onClick={handleOtp} disabled={connecting}>
                            <FontAwesomeIcon icon={faPhone} className='me-2' />{connecting ? "Verifying..." : "Connect Call"}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

FormModal.propTypes = {
    formModal: PropTypes.bool.isRequired,
    handleForm: PropTypes.func.isRequired,
    setCall: PropTypes.func.isRequired,
    defaultAgent: PropTypes.string.isRequired,
};

export default FormModal;
